import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import DocclikPlane from '../../images/docclikPlane.png';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  docclikPlane: {
    width: '300px',
  },
  errorContainer: {
    textAlign: 'center',
    fontSize: '1rem',
  },
}));

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>();
  const [token, setToken] = useState<string>('');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlToken = urlSearchParams.get('token');
  const classes = useStyles();

  useEffect(() => {
    urlToken && setToken(urlToken);
  }, [user, urlToken]);

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);

  return (
    <IntroContainer>
      {token && <DeviceSelectionScreen />}

      {!token && (
        <div className={classes.errorContainer}>
          <img src={DocclikPlane} className={classes.docclikPlane} />
          <div>Cannot access Doc View</div>
        </div>
      )}
    </IntroContainer>
  );
}
