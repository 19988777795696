import React from 'react';
import { makeStyles, Theme, Grid } from '@material-ui/core';
import DocclikLogo from '../../images/docclikLogo.png';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  grid: {
    paddingBottom: '4rem',
    marginLeft: '2rem',
  },
  docclikLogo: {
    width: '150px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <Grid className={classes.grid}>
        <img src={DocclikLogo} className={classes.docclikLogo} />
      </Grid>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
